import React from "react";

import Layout from "../../components/Layout";
import { Card, Label, List } from "semantic-ui-react";
import { Link } from "gatsby";

const ServicesLayout = () => (
  <Layout section="services">
    <h1>We want your success</h1>

    <Card.Group>
      <Card fluid color="blue">
        <Card.Content>
          <Card.Header>
            <Label ribbon>1</Label>
            <span>Install CYM-Identity</span>
          </Card.Header>
          <Card.Description>
            <p>
              CYM-Identity is a managed package, you'll need to install from the
              AppExchange.
            </p>
            <p>
              <Link to="/docs/guides/apis">Learn more</Link>
            </p>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Label.Group>
            <Label icon="user secret" content="System Administrator"></Label>
          </Label.Group>
        </Card.Content>
      </Card>

      <Card fluid color="blue">
        <Card.Content>
          <Card.Header>
            <Label ribbon>2</Label> Set up a Community
          </Card.Header>
          <Card.Description>
            <p>
              Deploy or use an existing Salesforce Community to host your
              CYM-Identity.
            </p>
            <p>
              <Link to="/docs/guides/apis">Learn more</Link>
            </p>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Label.Group>
            <Label icon="user secret" content="System Administrator"></Label>
          </Label.Group>
        </Card.Content>
      </Card>

      <Card fluid color="blue">
        <Card.Content>
          <Card.Header>
            <Label ribbon>3</Label> Assign the CYM-Identity Administrators
          </Card.Header>
          <Card.Description>
            <p>
              You can delegate the administration to other people from your
              organization
            </p>
            <p>
              <Link to="/docs/guides/apis">Learn more</Link>
            </p>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Label.Group>
            <Label icon="user secret" content="System Administrator"></Label>
          </Label.Group>
        </Card.Content>
      </Card>

      <Card fluid color="blue">
        <Card.Content>
          <Card.Header>
            <Label ribbon>4</Label> Set up a Realm
          </Card.Header>
          <Card.Description>
            <p>
              Realms allow you to create groups of applications which can
              communicate.
            </p>
            <p>
              <Link to="/docs/guides/apis">Learn more</Link>
            </p>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Label.Group>
            <Label icon="user secret" content="System Administrator"></Label>
            <Label icon="user" content="CYM-Identity Administrator"></Label>
          </Label.Group>
        </Card.Content>
      </Card>

      <Card fluid color="blue">
        <Card.Content>
          <Card.Header>
            <Label ribbon>5</Label> Set up a Client
          </Card.Header>
          <Card.Description>
            <p></p>
            <p>
              <Link to="/docs/guides/applications/web">Web apps</Link>
              <Link to="/docs/guides/applications/native">
                Native apps (Android, iOS & Desktop)
              </Link>
              <Link to="/docs/guides/applications/spa">
                Single Page applications (Javascript, Angular, React, Vue,
                Svelte, ...)
              </Link>
            </p>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Label.Group>
            <Label icon="user secret" content="System Administrator"></Label>
            <Label icon="user" content="CYM-Identity Administrator"></Label>
          </Label.Group>
        </Card.Content>
      </Card>

      <Card fluid color="blue">
        <Card.Content>
          <Card.Header>
            <Label ribbon>6</Label> Set up an API
          </Card.Header>
          <Card.Description>
            <p>Create an API and set up the policies to access it</p>
            <p>
              <Link
                to="/docs/guides/applications/web"
                style={{ display: "block" }}
              >
                Web apps
              </Link>
            </p>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Label.Group>
            <Label icon="user secret" content="System Administrator"></Label>
            <Label icon="user" content="CYM-Identity Administrator"></Label>
          </Label.Group>
        </Card.Content>
      </Card>

      <Card fluid color="blue">
        <Card.Content>
          <Card.Header>
            <Label ribbon>7</Label> Test your set up
          </Card.Header>
          <Card.Description>
            <p></p>
            <p>
              <Link to="/docs/guides/apis">Learn more</Link>
            </p>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Label.Group>
            <Label icon="user secret" content="System Administrator"></Label>
            <Label icon="user" content="CYM-Identity Administrator"></Label>
          </Label.Group>
        </Card.Content>
      </Card>

      <Card fluid color="blue">
        <Card.Content>
          <Card.Header>
            <Label ribbon>8</Label> Develop awesome experiences
          </Card.Header>
          <Card.Description>
            <p></p>
            <List>
              <List.Item>
                Authenticate users
                <List.List>
                  <List.Item>
                    <Link to="/docs/develop/authorization_code">
                      Authenticate users - AuthorizationCode flow
                    </Link>
                  </List.Item>
                  <List.Item>
                    <Link to="/docs/develop/ciba">
                      Authenticate users - CIBA flow
                    </Link>
                  </List.Item>
                </List.List>
              </List.Item>
              <List.Item>
                Session Management
                <List.List>
                  <List.Item>
                    <Link to="/docs/develop/refresh_token">
                      Keep your users logged in - RefreshToken flow
                    </Link>
                  </List.Item>
                  <List.Item>
                    <Link to="/docs/develop/end_session">
                      Logout your users - Logout flow
                    </Link>
                  </List.Item>
                  <List.Item>
                    <Link to="/docs/develop/userinfo">
                      Get users' profile - Userinfo
                    </Link>
                  </List.Item>
                  <List.Item>
                    <Link to="/docs/develop/revocation">Revocation</Link>
                  </List.Item>
                </List.List>
              </List.Item>
              <List.Item>
                Access APIs
                <List.List>
                  <List.Item>
                    <Link to="/docs/develop/introspection">
                      Validate Access Tokens - Introspection
                    </Link>
                  </List.Item>
                </List.List>
              </List.Item>
              <List.Item>
                Access application data
                <List.List>
                  <List.Item>
                    <Link to="/docs/develop/client_credentials">
                      Access application data - ClientCredentials flow
                    </Link>
                  </List.Item>
                </List.List>
              </List.Item>
            </List>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Label.Group>
            <Label icon="user outline" content="Application Developer"></Label>
          </Label.Group>
        </Card.Content>
      </Card>
    </Card.Group>
  </Layout>
);

export default ServicesLayout;
